import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GatsbyImage } from "gatsby-plugin-image";
import { TextSection } from "../TextSection/TextSection";
import * as styles from "./aboutProductDesktop.module.scss";
import { SanityProduct } from "../../../graphql-types";
import { usePageContext } from "../Context/pageContext";
import { useInView } from "react-intersection-observer";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHandSparkles,
  faMagnet,
  faRecycle,
  faStars,
  faTools,
} from "@fortawesome/pro-light-svg-icons";

interface Prop {
  product: SanityProduct;
}

library.add(faHandSparkles, faMagnet, faRecycle, faStars, faTools);

export const AboutProductDesktop: React.FC<Prop> = React.memo(({ product }) => {
  const { lang } = usePageContext();

  return (
    <div className={styles.container}>
      {product?.starAbouts?.map((e: any, index: number) => {
        const { ref, inView, entry } = useInView({
          threshold: 1,
          triggerOnce: true,
        });
        return (
          <div
            className={styles.section}
            key={`about-product-desktop${index}`}
            ref={ref}
          >
            {/* <div className={`${styles.image} ${inView && styles.fadeIn}`}>
              <SanityImage
                {...e.image}
                alt="concave"
                className={styles.sanImage}
              />
            </div> */}
            <GatsbyImage
              image={e?.image?.asset?.gatsbyImageData}
              alt={"concave"}
              className={`${styles.image} ${inView && styles.fadeIn}`}
            />
            <div className={`${styles.content} ${inView && styles.translate}`}>
              <FontAwesomeIcon
                icon={["fal", e?.icon]}
                className={styles.icon}
              />

              <div className={styles.title}>
                {e?._rawTitle?.[lang] as string}
              </div>
              <TextSection blocks={e?._rawContent?.[lang]} />
            </div>
          </div>
        );
      })}
    </div>
  );
});
