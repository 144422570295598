import React from "react";
import {
  SanityProduct,
  SanitySnipcartProduct,
  SanitySnipcartVariant,
} from "../../../graphql-types";
import { usePageContext } from "../Context/pageContext";
import { AddItemButton } from "../Snipcart/AddItemButton";
import * as styles from "./buybar.module.scss";

interface Props {
  product: SanitySnipcartProduct;
  setOpen: (value: boolean) => void;
  quantity: number;
  inView: Boolean;
  visible?: Boolean;
}

export const BuyBar: React.FC<Props> = React.memo(
  ({ product, setOpen, quantity, inView, visible }) => {
    const index = 0;
    const { lang } = usePageContext();

    return (
      <div
        className={`${styles.wrapper} ${
          (!visible || inView) && styles.invisible
        }`}
      >
        <div className={styles.price}>{`${
          index !== 0
            ? product?.variants?.[index]?.price
            : product?.defaultVariant?.price
        }€`}</div>

        <AddItemButton
          title={product?.snipcartTitle}
          variant={
            index === 0
              ? (product?.defaultVariant as SanitySnipcartVariant)
              : (product?.variants?.[index] as SanitySnipcartVariant)
          }
          slug={product?.slug?.current}
          excerpt={product?._rawDescription?.[lang]}
          // tva={0}
          quantity={quantity}
          image={
            (index === 0
              ? product?.defaultVariant?.images?.[0]?.image?.asset?.url
              : product?.variants?.[index]?.images?.[0]?.image?.asset?.url) ||
            product?.images?.[0]?.image?.asset?.url ||
            ""
          }
          buttonTitle={"Add to cart"}
        />
        {/* <AddToCartButton
        quantity={1}
        id={product.variants?.[index]?.admin_graphql_api_id}
      /> */}
      </div>
    );
  }
);
