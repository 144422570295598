import React, { useState } from "react";
import { graphql } from "gatsby";
import * as styles from "./Product.module.scss";
import { Layout } from "../../components/Layout/Layout";
import { AboutProductDesktop } from "../../components/Section/AboutProductDesktop";
import { AboutProductMobile } from "../../components/Section/AboutProductMobile";
import { AccordionDesktop } from "../../components/Accordion/AccordionDesktop";
import { AccordionMobile } from "../../components/Accordion/AccordionMobile";
import { FirstScreenProduct } from "../../modules/AboutProduct/FirstScreenProduct";
import { OnlyProduct } from "../../components/Section/OnlyProduct";
import { SEO } from "../../components/Seo";
import { usePageContext } from "../../components/Context/pageContext";
import { ProductPreview } from "../../components/Product/ProductPreview";
import { BuyBar } from "../../components/Product/BuyBar";
import {
  ProductTemplateQuery,
  SanityProductPage,
  SanitySnipcartProduct,
} from "../../../graphql-types";
import { AddToCartModal } from "../../components/PopUp/AddToCartModal";
import { useInView } from "react-intersection-observer";

interface Props {
  data: ProductTemplateQuery;
}

const Product: React.FC<Props> = ({ data }) => {
  const { lang } = usePageContext();
  const { page, product } = data;
  const [open, setOpen] = useState<boolean>(false);
  const [pop, setPop] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>(1);
  const [buyBarVisible, setBuyBarVisible] = useState<Boolean>(false);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.1,
  });

  return (
    <Layout>
      <SEO />
      <BuyBar
        product={product as SanitySnipcartProduct}
        setOpen={setOpen}
        quantity={quantity}
        inView={inView}
        visible={buyBarVisible}
      />
      <div className={styles.wrapper}>
        <div className={styles.pageContainer}>
          <FirstScreenProduct
            product={product as SanitySnipcartProduct}
            page={page as SanityProductPage}
            quantity={quantity}
            setQuantity={setQuantity}
            pop={pop}
            setPop={setPop}
            setBuyBarVisible={setBuyBarVisible}
          />

          <AccordionDesktop product={product} />
          <AccordionMobile product={product} />

          {open && (
            <div>
              {/* <AddToCartPopUp setPop={setOpen} /> */}
              <AddToCartModal setPop={setOpen} />
            </div>
          )}
          <div className={styles.separate} id="aboutProduct"></div>
          <div className={styles.title}>
            {page?.content?._rawAboutLinkTitle?.[lang] as string}
          </div>
          <AboutProductDesktop product={product as SanitySnipcartProduct} />
          <AboutProductMobile product={product as SanitySnipcartProduct} />

          <div className={styles.separate} id="onlyProduct"></div>
          <div className={styles.title}>
            {page?.content?._rawOnlyLinkTitle?.[lang] as string}
          </div>

          <OnlyProduct product={product as SanitySnipcartProduct} />

          <div ref={ref}>
            <div className={styles.separate}></div>
            <div className={styles.lasttitle}>You may also like...</div>
            <ProductPreview
              image={
                product?.starSuggestions?.[0]?.images?.[0]?.image?.asset
                  ?.gatsbyImageData
              }
              slug={product?.starSuggestions?.[0]?.slug?.current || ""}
              price={product?.starSuggestions?.[0]?.defaultVariant?.price}
              title={product?.starSuggestions?.[0]?._rawTitle?.[lang]}
              alt={product?.starSuggestions?.[0]?.images?.[0]?._rawAlt?.[lang]}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Product;

export const PRODUCTTEMPLATE = graphql`
  query ProductTemplate($id: String) {
    product: sanitySnipcartProduct(id: { eq: $id }) {
      snipcartTitle
      id
      _rawTitle
      _rawDescription
      slug {
        current
      }
      content {
        _rawTitle
        _rawText
      }
      options {
        _rawTitle
        values {
          _rawTitle
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        display
      }
      images {
        _rawAlt
        image {
          asset {
            url
            gatsbyImageData(placeholder: NONE)
          }
        }
      }
      status
      defaultVariant {
        price
        sku
        # taxable
        option1
        option2
        option3
        weight
        images {
          _rawAlt
          image {
            asset {
              url
              gatsbyImageData(placeholder: NONE)
            }
          }
        }
      }
      variants {
        price
        sku
        # taxable
        option1
        option2
        option3
        weight
        images {
          _rawAlt
          image {
            asset {
              url
              gatsbyImageData
            }
          }
        }
      }
      starAbouts {
        _id
        _rawTitle
        _rawContent
        image {
          asset {
            gatsbyImageData
          }
        }
        icon
      }
      starOnlies {
        _rawContent
        _rawTitle
        image {
          asset {
            gatsbyImageData
          }
        }
      }
      starSuggestions {
        _rawTitle
        images {
          _rawAlt
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        defaultVariant {
          price
        }
        slug {
          current
        }
      }
    }
    page: sanityProductPage {
      content {
        _rawAboutLinkTitle
        _rawOnlyLinkTitle
      }
    }
  }
`;
