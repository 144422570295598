import React from "react";
import * as styles from "./StyledButton.module.scss";

export interface StyledButtonProps {
  type?: "button" | "reset" | "submit";
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  className?: string;
  children: React.ReactNode;
}

export const StyledButton: React.FC<StyledButtonProps> = ({
  type = "button",
  onClick,
  className,
  children,
}) => {
  return (
    <button
      type={type}
      onClick={(e) => {
        onClick && onClick(e);
      }}
      className={`${styles.button} ${className}`}
    >
      {children}
    </button>
  );
};
