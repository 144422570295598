// extracted by mini-css-extract-plugin
export var container = "aboutProductDesktop-module--container--2_sk1";
export var section = "aboutProductDesktop-module--section--2jJpw";
export var sanImage = "aboutProductDesktop-module--sanImage--Ny35y";
export var image = "aboutProductDesktop-module--image--1WhrM";
export var fadeIn = "aboutProductDesktop-module--fadeIn--3OqjW";
export var content = "aboutProductDesktop-module--content--2xrQ7";
export var icon = "aboutProductDesktop-module--icon--Tzxd-";
export var title = "aboutProductDesktop-module--title--2xiaL";
export var translate = "aboutProductDesktop-module--translate--3AeMB";
export var moveL = "aboutProductDesktop-module--moveL--1i6ii";
export var moveR = "aboutProductDesktop-module--moveR--BjmeP";