import React from "react";
import * as styles from "./textsection.module.scss";
import BlockContent from "@sanity/block-content-to-react";
import { SanityBlock } from "../../../graphql-types";

interface TextSectionProp {
  blocks: SanityBlock;
}

export const TextSection: React.FC<TextSectionProp> = ({ blocks }) => (
  <div className={styles.text}>
    <BlockContent blocks={blocks} />
  </div>
);
