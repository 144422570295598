import React, { useState } from "react";
import { SanitySnipcartProduct } from "../../../graphql-types";
import { usePageContext } from "../Context/pageContext";
import BlockContent from "@sanity/block-content-to-react";
import * as styles from "./accordiondesktop.module.scss";

interface Props {
  product: SanitySnipcartProduct;
}

export const AccordionDesktop: React.FC<Props> = React.memo(({ product }) => {
  const [open, setOpen] = useState<number>(0);
  const { lang } = usePageContext();
  return (
    <div className={styles.container}>
      <div className={styles.bar}>
        {product?.content?.map((item: SanityProductDescItem, index: number) => (
          <div
            onClick={() => setOpen(index)}
            className={open === index ? styles.titleOpen : styles.title}
            key={`descTitle-${index}`}
          >
            {item?._rawTitle?.[lang]}
          </div>
        ))}
      </div>
      <div className={styles.content}>
        {product?.content?.map((item: SanityProductDescItem, index: number) => (
          <>
            {open === index && (
              <div className={styles.text} key={`descContent-${index}`}>
                <BlockContent blocks={item?._rawText?.[lang]} />
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
});
