// extracted by mini-css-extract-plugin
export var wrapper = "firstScreenProduct-module--wrapper--2_9_Z";
export var description = "firstScreenProduct-module--description--wUo2-";
export var about = "firstScreenProduct-module--about--T_Yd6";
export var only = "firstScreenProduct-module--only--3VntF";
export var product = "firstScreenProduct-module--product--3HRKF";
export var headerMobile = "firstScreenProduct-module--headerMobile--32TE_";
export var titleProduct = "firstScreenProduct-module--titleProduct--CxL_O";
export var subtitleProduct = "firstScreenProduct-module--subtitleProduct--BIk-F";
export var headerDesktop = "firstScreenProduct-module--headerDesktop--g-qBK";
export var sell = "firstScreenProduct-module--sell--2UAgi";
export var option = "firstScreenProduct-module--option--16VI5";
export var toCart = "firstScreenProduct-module--toCart--35Gye";
export var postionPrice = "firstScreenProduct-module--postionPrice--2g6-L";
export var slider = "firstScreenProduct-module--slider--6lFld";
export var carouselContainer = "firstScreenProduct-module--carouselContainer--2hP-F";
export var controls = "firstScreenProduct-module--controls--2r2Rh";
export var space = "firstScreenProduct-module--space--1tw03";
export var popup = "firstScreenProduct-module--popup--EKBDF";
export var swiperContainer = "firstScreenProduct-module--swiperContainer--1szp2";