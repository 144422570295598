import React, { useEffect, useState } from "react";
import Swiper from "swiper";
import { AddToCartButton } from "../../components/Buttons/AddToCartButton";
import { Carousel } from "../../components/Carousel/Carousel";
import { CarouselControls } from "../../components/Carousel/CarouselControls";
import { CarouselPagination } from "../../components/Carousel/CarouselPagination";
import { Price } from "../../components/Price/Price";
import { SelectorColor } from "../../components/SelectorColor/SelectorColor";
import { SelectorQuantity } from "../../components/SelectorQuantity/SelectorQuantity";
import { SwiperSlide } from "swiper/react";
import * as styles from "./firstScreenProduct.module.scss";
import {
  SanitySnipcartProduct,
  SanityProductPage,
  SanityMainImage,
  SanitySnipcartVariant,
} from "../../../graphql-types";
import { usePageContext } from "../../components/Context/pageContext";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { AddItemButton } from "../../components/Snipcart/AddItemButton";
import { AddToCartModal } from "../../components/PopUp/AddToCartModal";

interface Props {
  product: SanitySnipcartProduct;
  page: SanityProductPage;
  quantity: number;
  setQuantity: (value: number) => void;
  pop: boolean;
  setPop: (value: boolean) => void;
  setBuyBarVisible: any;
}

export const FirstScreenProduct: React.FC<Props> = React.memo(
  ({ product, page, quantity, setQuantity, pop, setPop, setBuyBarVisible }) => {
    const { lang } = usePageContext();
    const [swiper, setSwiper] = useState<Swiper>();
    const [index, setIndex] = useState<number>(0);

    useEffect(() => {
      const { Snipcart } = window;
      if (!Snipcart) return;
      // open modal on snipcart event add item on cart
      Snipcart.events.on("item.adding", () => {
        setPop(true);
        // Snipcart.api.theme.cart.open();
      });
    }, []);

    return (
      <div className={styles.wrapper}>
        <div className={styles.description}>
          <div className={styles.about}>
            <Link to="#aboutProduct">
              {page?.content?._rawAboutLinkTitle?.[lang]}
            </Link>
          </div>
          <div className={styles.only}>
            <Link to="#onlyProduct">
              {page?.content?._rawOnlyLinkTitle?.[lang]}
            </Link>
          </div>
        </div>
        <div className={styles.product}>
          <div className={styles.headerMobile}>
            <div className={styles.titleProduct}>
              {product?._rawTitle?.[lang]}
            </div>
            <div className={styles.subtitleProduct}>
              {product?._rawDescription?.[lang]}
            </div>
          </div>

          <div className={styles.slider}>
            <div className={styles.carouselContainer}>
              <Carousel
                setSwiper={setSwiper}
                spaceBetween={50}
                swiperContainerClass={styles.swiperContainer}
              >
                {(index === 0 ? product?.defaultVariant?.images : [])
                  ?.concat(
                    index !== 0
                      ? (product?.variants?.[index]?.images as [])
                      : []
                  )
                  ?.concat(product?.images || [])
                  .map((mainImage: any, index: number) => (
                    <SwiperSlide key={`slide-${index}`}>
                      <GatsbyImage
                        image={mainImage?.image?.asset?.gatsbyImageData}
                        alt={mainImage._rawAlt?.[lang] || ""}
                        objectFit={"contain"}
                        style={{ height: "100%" }}
                        imgStyle={{ height: "100%" }}
                      />
                    </SwiperSlide>
                  ))}
              </Carousel>
            </div>
            <div className={styles.controls}>
              <CarouselControls swiper={swiper}>
                <CarouselPagination swiper={swiper} spacing={10} />
              </CarouselControls>
            </div>
          </div>

          <div className={styles.space}></div>
          <div className={styles.headerDesktop}>
            <div className={styles.titleProduct}>
              {product?._rawTitle?.[lang]}
            </div>
            <div className={styles.subtitleProduct}>
              {product?._rawDescription?.[lang]}
            </div>
            <div className={styles.sell}>
              <div className={styles.option}>
                {/* <SelectorColor product={product} setIndex={setIndex} /> */}
                <SelectorQuantity
                  quantity={quantity}
                  setQuantity={setQuantity}
                />
              </div>
              <div className={styles.toCart}>
                <div className={styles.postionPrice}>
                  <Price
                    price={
                      index === 0
                        ? product?.defaultVariant?.price
                        : product?.variants?.[index]?.price
                    }
                  />
                </div>
                <AddItemButton
                  title={product?.snipcartTitle}
                  variant={
                    index === 0
                      ? (product?.defaultVariant as SanitySnipcartVariant)
                      : (product?.variants?.[index] as SanitySnipcartVariant)
                  }
                  slug={product?.slug?.current}
                  excerpt={product?._rawDescription?.[lang]}
                  // tva={0}
                  quantity={quantity}
                  image={
                    (index === 0
                      ? product?.defaultVariant?.images?.[0]?.image?.asset?.url
                      : product?.variants?.[index]?.images?.[0]?.image?.asset
                          ?.url) ||
                    product?.images?.[0]?.image?.asset?.url ||
                    ""
                  }
                  buttonTitle={"Add to cart"}
                  setBuyBarVisible={setBuyBarVisible}
                />
              </div>
            </div>
          </div>
          {pop && (
            <div className={styles.popup}>
              <AddToCartModal setPop={setPop} />
            </div>
          )}
        </div>
      </div>
    );
  }
);
