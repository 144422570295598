import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GatsbyImage } from "gatsby-plugin-image";
import { TextSection } from "../TextSection/TextSection";
import * as styles from "./aboutProductMobile.module.scss";
import { SanityProduct, SanityProductAboutItem } from "../../../graphql-types";
import { usePageContext } from "../Context/pageContext";
import { useInView } from "react-intersection-observer";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHandSparkles,
  faMagnet,
  faRecycle,
  faStars,
  faTools,
} from "@fortawesome/pro-light-svg-icons";
import SanityImage from "gatsby-plugin-sanity-image";

interface Prop {
  product: SanityProduct;
}

library.add(faHandSparkles, faMagnet, faRecycle, faStars, faTools);

export const AboutProductMobile: React.FC<Prop> = React.memo(({ product }) => {
  const { lang } = usePageContext();
  return (
    <div className={styles.container}>
      {product?.starAbouts?.map((e: SanityProductAboutItem, index: number) => {
        const { ref, inView, entry } = useInView({
          threshold: 1,
          triggerOnce: true,
        });
        return (
          <div key={`about-product-mobile${index}`} ref={ref}>
            <div className={`${styles.position} ${inView && styles.fade}`}>
              {/* <SanityImage
                {...e.image}
                alt="concave"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              /> */}
              <GatsbyImage
                image={e?.image?.asset?.gatsbyImageData}
                alt={"concave"}
              />
            </div>
            <div className={`${styles.initial} ${inView && styles.translate}`}>
              <FontAwesomeIcon
                icon={["fal", e?.icon]}
                className={styles.icon}
              />
              <div className={styles.subtitle}>
                {e?._rawTitle?.[lang] as string}
              </div>
              <TextSection blocks={e?._rawContent?.[lang]} />
            </div>
          </div>
        );
      })}
    </div>
  );
});
