import React from "react";
import * as styles from "./price.module.scss";

interface Props {
  price: any;
}

export const Price: React.FC<Props> = ({ price }) => {
  return <div className={styles.price}>{price}€</div>;
};
