import React, { useEffect, useRef, useState } from "react";
import { StyledButton } from "../Buttons/StyledButton";
import * as styles from "./addToCartModal.module.scss";

declare const window: any;

interface Props {
  setPop: (value: boolean) => void;
}

export const AddToCartModal: React.FC<Props> = ({ setPop }) => {
  const [open, toggleOpen] = useState(false);
  const [item, setItem] = useState({});
  // hidden button for open the cart
  const bt_cart = useRef();
  // mask under modal
  const mask = useRef();
  useEffect(() => {
    const { Snipcart } = window;
    if (!Snipcart) return;
    // open modal on snipcart event add item on cart
    Snipcart.events.on("item.adding", (_item: any) => {
      setItem(_item);
      toggleOpen(true);
    });
  }, []);

  return (
    <>
      <div
        ref={mask}
        className={`${open === true ? styles.show : ""} ${styles.box}`}
      >
        <div className={styles.content}>You’ve made a very good choice ;)</div>
        <div className={styles.add__cart} role="alertdialog">
          {/* <div className={styles.confirm}>
            {item.name && (
              <span>
                <strong>
                  {item.quantity} {item.name}
                </strong>{" "}
                {"added to your cart"}
              </span>
            )}
          </div> */}
          <div className={styles.actions}>
            <StyledButton onClick={() => setPop(false)} className={styles.back}>
              BACK TO SHOP
            </StyledButton>

            <StyledButton
              className={styles.got_cart}
              onClick={() => {
                toggleOpen(false);
                bt_cart.current.click();
                setPop(false);
              }}
            >
              GO TO CART
            </StyledButton>
          </div>
        </div>
      </div>
      <button
        ref={bt_cart}
        style={{ height: 0, opacity: 0 }}
        className="snipcart-checkout"
      ></button>
    </>
  );
};
