import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import * as styles from "./accordionMobile.module.scss";
import BlockContent from "@sanity/block-content-to-react";
import { usePageContext } from "../Context/pageContext";

interface Props {
  product: SanitySnipcartProduct;
}

export const AccordionMobile: React.FC<Props> = React.memo(({ product }) => {
  const [open, setOpen] = useState<number>();
  const { lang } = usePageContext();

  return (
    <div className={styles.container}>
      {product?.content?.map((item: SanityProductDescItem, index: number) => (
        <>
          <div
            onClick={() => setOpen(index)}
            className={
              open === index ? styles.titleAccordion : styles.titleAccordionOpen
            }
          >
            <div className={open === index ? styles.title : styles.titleOpen}>
              {item?._rawTitle?.[lang]}
            </div>
            <FontAwesomeIcon
              className={open === index ? styles.icon : styles.iconOpen}
              icon={open === index ? faChevronUp : faChevronDown}
            />
          </div>
          {open === index && (
            <div className={styles.panel}>
              <BlockContent blocks={item?._rawText?.[lang]} />
            </div>
          )}
        </>
      ))}
    </div>
  );
});
