import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { TextSection } from "../TextSection/TextSection";
import * as styles from "./onlyProduct.module.scss";
import { usePageContext } from "../Context/pageContext";
import { SanityProduct } from "../../../graphql-types";
import { useInView } from "react-intersection-observer";
import SanityImage from "gatsby-plugin-sanity-image";

interface Prop {
  product: SanityProduct;
}

export const OnlyProduct: React.FC<Prop> = React.memo(({ product }) => {
  const { lang } = usePageContext();

  return (
    <div className={styles.section} id="onlyProduct">
      {product?.starOnlies?.map((e: any, index: number) => {
        const { ref, inView, entry } = useInView({
          threshold: 0.8,
          triggerOnce: true,
        });

        return (
          <div
            className={styles.position}
            ref={ref}
            key={`only-product${index}`}
          >
            <div className={`${styles.content} ${inView && styles.translate}`}>
              {/* <SanityImage {...e.image} alt="concave" /> */}
              <GatsbyImage
                image={e?.image?.asset?.gatsbyImageData}
                alt={"concave"}
              />
              <div className={styles.title}>
                {e?._rawTitle?.[lang] as string}
              </div>
              <div className={styles.text}>
                <TextSection blocks={e?._rawContent?.[lang]} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
});
